import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const ContactPage = ({ location }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO pathname={location.pathname} title={intl.formatMessage({ id: 'contact-page-title' })} />

      <div className="relative w-11/12 max-w-screen-md mx-auto pt-24">
        <h1 className="font-bold text-2xl text-center mb-24">{intl.formatMessage({ id: 'contact-page-title' })}</h1>

        <form className="grid grid-cols-2 gap-8" name={`contact-${intl.locale}`} method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value={`contact-${intl.locale}`} />

          <div className="form-field">
            <label>{intl.formatMessage({ id: 'contact-form-name-label' })}</label>
            <input className="text-field" type="text" name="name" />
          </div>

          <div className="form-field">
            <label>{intl.formatMessage({ id: 'contact-form-email-label' })}</label>
            <input className="text-field" type="email" name="email" />
          </div>

          <div className="form-field col-span-2">
            <label>{intl.formatMessage({ id: 'contact-form-comment-label' })}</label>
            <textarea name="message" className="text-field" />
          </div>

          <div className="col-span-2 flex justify-center items-center">
            <button type="submit" className="submit-btn">
              {intl.formatMessage({ id: 'contact-form-submit' })}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ContactPage;
